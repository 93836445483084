<template>
  <a class="m-4 dark:text-white text-gray hover:text-blue-hover	" :href="link">
    <i :class="icon"></i>
  </a>
</template>

<script>
export default {
  props: {
    link: String,
    icon: String,
  },
};
</script>

<style scoped>
.text-gray { 
  color: #6e6e73;
}
.hover\:text-blue-hover:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 122, 255, var(--tw-text-opacity));
}
</style>