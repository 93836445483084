<template>
  <div class="pt-6 md:p-8 text-center md:text-center space-y-4">
    <SocialsIcon link="http://spotify.omarja.com" icon="fab fa-spotify fa-2x" />
    <SocialsIcon link="https://github.com/OmarJalil" icon="fab fa-github fa-2x" />
    <SocialsIcon link="https://www.linkedin.com/in/omarjalilfierro/" icon="fab fa-linkedin-in fa-2x" />
    <SocialsIcon link="https://blog.omarja.com/" icon="fas fa-book fa-2x" />
    <SocialsIcon link="mailto:omarjalilfierro@gmail.com" icon="fas fa-envelope fa-2x" />
  </div>
</template>

<script>
import SocialsIcon from "./SocialsIcon.vue"

export default { 
  components: {
    SocialsIcon
  }
};
</script>

<style>
</style>