<template>
  <figure class="md:flex md:max-w-4xl md:bg-secondary rounded-xl p-8 md:p-0">
    <img
      class="w-32 h-32 md:w-1/3 md:h-auto md:rounded-none rounded-full mx-auto width=421 height=421"
      src="../assets/me-hello.png"
      alt="Me hi"
      loading="lazy"
    />
    <div class="pt-6 md:p-8 text-center md:text-left space-y-4">
      <h1 class="text-4xl font-semibold gradient-title">
        <TypeSlider
          v-cloak
          :speed="150"
          :full-erase="true"
          :interval="200"
          :words="['Hi what\'s up?', 'Que onda?', 'Salut ça va?']"
        >
        </TypeSlider>
      </h1>
      <blockquote>
        <p class="text-lg dark:text-white">
          <strong class="">Hi I'm Jalil</strong>
          , iOS developer
          passionate about learning
          and teaching, lately interested in the open-source
          project community.
        </p>
      </blockquote>
      <Socials />
    </div>
  </figure>
</template>

<script>
import Socials from "./Socials.vue";
import TypeSlider from "./TypeSlider";

export default {
  components: { Socials, TypeSlider },
};
</script>

<style>
.bg-secondary { 
    background-color: #f2f2f2;
}
.gradient-title { 
  background: linear-gradient(to right, #f06844 0%, #ee4c54 25%, #d45e95 50%, #9c6ca6 75%, #6583c1 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>